<template>
    <div class="content">
        <div class="row">
        <h2>Three.js</h2>
        <a href="https://threejs.org" target="blank">Three.js</a>
        </div>

        <div id="three-container">
            <!--<Torus></Torus> 
            <ObjLoader :objPath="city" :mtlPath="null"></ObjLoader>-->
            <div class="three-scene">
            <ObjLoader :objPath="TowerHouse" :mtlPath="TowerHouseMtl" :rendererWidth="sceneWidth" :rendererHeight="sceneHeight"></ObjLoader>
        </div>
            <p>https://sketchfab.com/3d-models/tower-house-design-a972a15421a942429f1279824e00c042</p>
            <div class="three-scene"><GLTFLoader :modelPath="puzzle" :rendererWidth="sceneWidth" :rendererHeight="sceneHeight"></GLTFLoader></div>
           <p>https://sketchfab.com/3d-models/optical-illusion-puzzle-e95ef9282c934e50a84f537add9e322c</p>
           <div class="three-scene"><ObjLoader :objPath="puzzle_cube_obj" :mtlPath="puzzle_cube_mtl" :rendererWidth="sceneWidth" :rendererHeight="sceneHeight"></ObjLoader></div>
           <p>https://sketchfab.com/3d-models/puzzle-cube-challenge-not-rubiks-cube-1-357118cbdb0e4d62ae91dbd8cb3c4614</p>
        </div>
    </div>
</template>


<script setup>
//import Torus from '@/components/showcase/Torus.vue';
import ObjLoader from '@/components/helpers/3d-models/ObjLoader.vue';
import GLTFLoader from "@/components/helpers/3d-models/GLTFLoader.vue";

//import city from '@/assets/showcase/3d-models/city/city.obj';
import TowerHouse from '@/assets/showcase/3d-models/tower-house/TowerHouse.obj';
import TowerHouseMtl from '@/assets/showcase/3d-models/tower-house/TowerHouse.mtl';
//const puzzle = '/showcase/3d-models/puzzle/scene.gltf';
const puzzle = '/3d-models/puzzle/scene.gltf';

const puzzle_cube_obj = '/3d-models/puzzle-cube/tinker.obj';
const puzzle_cube_mtl = '/3d-models/puzzle-cube/tinker.mtl';

const sceneWidth = 1000;
const sceneHeight = 1000;

console.log(sceneWidth, sceneHeight);

</script>

<style scoped>
.three-scene {
    border: 1px solid black;
    width: 100%;
    height: 100%;
}
</style>