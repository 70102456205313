<template>
  <div ref="rendererContainer" style="width: 100%; height: 100vh;"></div>
</template>

<script>
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

export default {
  name: 'ObjGltfLoader',
  props: {
    modelPath: {
      type: String,
      required: true,
    },
    rendererWidth: {
      type: Number,
      required: true,
    },
    rendererHeight: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.initThree();
  },
  methods: {
    initThree() {
  // Set up the scene, camera, and renderer
  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(75, this.rendererWidth / this.rendererHeight, 0.1, 1000);
  const renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.setSize(this.rendererWidth, this.rendererHeight);
  this.$refs.rendererContainer.appendChild(renderer.domElement);

  // Set background color
  renderer.setClearColor(0xaaaaaa, 1); // Light grey background

  // Add OrbitControls for mouse interaction
  const controls = new OrbitControls(camera, renderer.domElement);
  controls.enableDamping = true;
  controls.dampingFactor = 0.25;
  controls.screenSpacePanning = false;
  controls.minDistance = 1;
  controls.maxDistance = 500;

  // Add a light source
  const ambientLight = new THREE.AmbientLight(0xffffff, 1); // Increase intensity
  scene.add(ambientLight);

  const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
  directionalLight.position.set(10, 10, 10);
  scene.add(directionalLight);

  const gltfLoader = new GLTFLoader();
  console.log(this.modelPath)
  gltfLoader.load(
    this.modelPath,
    (gltf) => {
      const model = gltf.scene;

      // Check if model materials react to lights
      model.traverse((node) => {
        if (node.isMesh) {
          node.material = new THREE.MeshStandardMaterial({
            color: node.material.color,
            roughness: 0.5, // Adjust properties as needed
            metalness: 0.5,
          });
        }
      });

      scene.add(model);
      camera.position.set(0, 2, 5);
    },
    undefined,
    (error) => {
      console.error('An error occurred while loading the GLTF model:', error);
    }
  );

  // Render loop
  const animate = () => {
    requestAnimationFrame(animate);
    controls.update();
    renderer.render(scene, camera);
  };
  animate();

  // Handle window resize
  window.addEventListener('resize', () => {
    camera.aspect = this.rendererWidth / this.rendererHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(this.rendererWidth, this.rendererHeight);
  });
},

  },
};
</script>

<style scoped>
/* Optional: Add styles for your component */
</style>
