<template>
  <div ref="rendererContainer" style="width: 100%; height: 100vh"></div>
</template>

<script>
import * as THREE from "three";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

export default {
  name: "ObjLoader",
  props: {
    objPath: {
      type: String,
      required: true,
    },
    mtlPath: {
      type: String,
      required: true,
    },
    rendererWidth: {
      type: Number,
      required: true,
    },
    rendererHeight: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    if (this.mtlPath == "" || this.mtlPath == null) {
      this.initThree();
    } else {
      this.initThreeMTL();
    }
  },
  methods: {
    initThreeMTL() {
      // Set up the scene, camera, and renderer
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(
        75,
        this.rendererWidth / this.rendererHeight,
        0.1,
        1000
      );
      const renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(this.rendererWidth, this.rendererHeight);
      this.$refs.rendererContainer.appendChild(renderer.domElement);

      // Add OrbitControls for mouse interaction
      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;
      controls.dampingFactor = 0.25;
      controls.screenSpacePanning = false;
      controls.minDistance = 1;
      controls.maxDistance = 500;

      // Add a light source
      const ambientLight = new THREE.AmbientLight(0xffdfb5 , 1); // Increase intensity
      scene.add(ambientLight);

      // Load the MTL file
      const mtlLoader = new MTLLoader();
      const objLoader = new OBJLoader();

      mtlLoader.load(this.mtlPath, (materials) => {
        materials.preload();
        objLoader.setMaterials(materials);

        renderer.setClearColor(0xffffff, 1);

        objLoader.load(
          this.objPath, // Use the prop for the object path
          (object) => {
            // Scale down the object if it's too large
            object.scale.set(0.1, 0.1, 0.1); // Adjust scaling based on your model's size
            object.receiveShadow = true;
            object.castShadow = true;
            scene.add(object);
            // Adjust camera position if necessary
            camera.position.set(0, 2, 5);
          },
          (xhr) => {
            console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
          },
          (error) => {
            console.error("An error happened", error);
          }
        );
      });

      // Render loop
      const animate = () => {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, camera);
      };
      animate();

      // Handle window resize
      window.addEventListener("resize", () => {
        camera.aspect = this.rendererWidth / this.rendererHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(this.rendererWidth, this.rendererHeight);
      });
    },

    initThree() {
      // Set up the scene, camera, and renderer
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(
        75,
        this.rendererWidth / this.rendererHeight,
        0.1,
        1000
      );
      const renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(this.rendererWidth, this.rendererHeight);
      this.$refs.rendererContainer.appendChild(renderer.domElement);

      // Add OrbitControls for mouse interaction
      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;
      controls.dampingFactor = 0.25;
      controls.screenSpacePanning = false;
      controls.minDistance = 1;
      controls.maxDistance = 500;

      renderer.setClearColor(0xffffff, 1);

      // Add a light source
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.9);
      scene.add(ambientLight);

      const pointLight = new THREE.PointLight(0xffffff, 1);
      pointLight.position.set(10, 10, 10);
      scene.add(pointLight);

      // Load the OBJ file
      const objLoader = new OBJLoader();
      objLoader.load(
        this.objPath, // Use the prop for the object path
        (object) => {
          // Scale down the object if it's too large
          object.scale.set(0.1, 0.1, 0.1); // Adjust scaling based on your model's size
          scene.add(object);
          // Adjust camera position if necessary
          camera.position.set(0, 2, 5);
        },
        (xhr) => {
          console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
        },
        (error) => {
          console.error("An error happened", error);
        }
      );

      // Render loop
      const animate = () => {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, camera);
      };
      animate();

      // Handle window resize
      window.addEventListener("resize", () => {
        camera.aspect = this.rendererWidth / this.rendererHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(this.rendererWidth, this.rendererHeight);
      });
    },
  },
};
</script>
